var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('keep-alive',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"items":_vm.users,"fields":_vm.fields,"table-filter":"","column-filter":"","items-per-page-select":{values:[10,25,50,100,1000]},"items-per-page":25,"hover":"","sorter":{ external: false, resetable: true },"pagination":"","loading":_vm.usersLoading,"clickable-rows":""},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.json)?_c('img',{staticClass:"c-avatar-img",staticStyle:{"max-width":"50px"},attrs:{"src":item.json.profile_image_url,"alt":""}}):_vm._e()])]}},{key:"tags",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.tags),function(m,index){return _c('CBadge',{key:index,staticClass:"mr-1",attrs:{"href":"#","color":"dark"}},[_vm._v(_vm._s(m))])}),1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('strong',[_vm._v(_vm._s(item.name)+" "),(item.verified === true)?_c('CIcon',{attrs:{"name":"cil-check-circle"}}):_vm._e()],1),_vm._v(" "),_c('em',[_vm._v("@"+_vm._s(item.screen_name))])])]}},{key:"show_details",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleDetails(index)}}},[_vm._v(" "+_vm._s(Boolean(_vm.itemToggler[index]) ? 'Hide' : 'Show')+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }