<template>
    <keep-alive>
        <CRow>
            <CCol sm="12">
                <CDataTable
                        :items="users"
                        :fields="fields"
                        table-filter
                        column-filter
                        :items-per-page-select="{values:[10,25,50,100,1000]}"
                        :items-per-page="25"
                        hover
                        :sorter="{ external: false, resetable: true }"
                        pagination
                        :loading="usersLoading"
                        @row-clicked="rowClicked"
                        clickable-rows
                >
                    <template #avatar="{item}">
                        <td>
                            <img v-if="item.json" :src="item.json.profile_image_url" class="c-avatar-img" alt="" style="max-width: 50px">
                        </td>
                    </template>
                    <template #tags="{item}">
                        <td>
                            <CBadge href="#" color="dark" v-for="(m, index) in item.tags" :key="index" class="mr-1">{{m}}</CBadge>
                        </td>
                    </template>
                    <template #name="{item}">
                        <td>
                            <strong>{{item.name}}
                                <CIcon v-if="item.verified === true" name="cil-check-circle"/>
                            </strong> <em>@{{item.screen_name}}</em>
                        </td>
                    </template>

                    <!--                    <template #status="{item}">
                                            <td>
                                                <CBadge :color="getBadge(item.status)">
                                                    {{item.status}}
                                                </CBadge>
                                            </td>
                                        </template>-->
                    <template #show_details="{item, index}">
                        <td class="py-2">
                            <CButton
                                    color="primary"
                                    variant="outline"
                                    square
                                    size="sm"
                                    @click="toggleDetails(index)"
                            >
                                {{Boolean(itemToggler[index]) ? 'Hide' : 'Show'}}
                            </CButton>
                        </td>
                    </template>
                    <!--                    <template #details="{item, index}">-->
                    <!--                        <CCollapse :show="Boolean(itemToggler[index])" :duration="collapseDuration">-->
                    <!--                            <CCard>-->
                    <!--                                <CCardBody>-->
                    <!--                                    <CRow>-->
                    <!--                                        <CCol col="auto">-->
                    <!--                                            <img :src="getFullProfilePicUrl(item.json.profile_image_url)" class="c-avatar-img" alt="" style="max-width: 200px">-->
                    <!--                                        </CCol>-->
                    <!--                                        <CCol col="8">-->
                    <!--                                            <p class="mb-3">{{item.description}}</p>-->
                    <!--                                            <span class="mr-1 d-block">{{item.json.location}}</span>-->
                    <!--&lt;!&ndash;                                            <span class="mr-1 d-block" v-if="item.json.entities.url.urls"><a target="_blank" :href="item.json.entities.url.urls[0].expanded_url">{{item.json.entities.url.urls[0].display_url}}</a></span>&ndash;&gt;-->
                    <!--                                            <span class="mr-1 d-block">Seit <span class="text-value">{{getDateInFormat(item.json.created_at, 'P')}}</span> bei Twitter</span>-->
                    <!--                                            <span class="mr-1 d-block"><strong>{{item.followers}}</strong> Folge ich</span>-->
                    <!--                                            <span class="mr-1 d-block"><strong>{{item.followings}}</strong> Follower</span>-->
                    <!--                                            <span class="mr-1 d-block"><strong>{{item.tweetnumber}}</strong> Tweets</span>-->
                    <!--                                        </CCol>-->
                    <!--                                    </CRow>-->
                    <!--                                    <CRow>-->
                    <!--                                        <CCol class="mt-3">-->
                    <!--                                            <TagSelect></TagSelect>-->
                    <!--                                        </CCol>-->
                    <!--                                    </CRow>-->
                    <!--                                </CCardBody>-->
                    <!--                            </CCard>-->
                    <!--                        </CCollapse>-->
                    <!--                    </template>-->
                </CDataTable>
            </CCol>
        </CRow>
    </keep-alive>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {format} from 'date-fns'
    import {de} from 'date-fns/locale'

    import TagSelect from "./modules/TagSelect";

    const fields = [
        {
            key: 'avatar',
            label: '',
            _style: 'width:100px',
            sorter: false,
            filter: false
        },
        // {
        //     key: 'select',
        //     label: '',
        //     _style: 'width:1%',
        //     sorter: false,
        //     filter: false
        // },
        // {key: 'id', _style: 'width:1%'},
        {
            key: 'name',
            label: 'Name',
            filter: false,
        },
        {key: 'tags', label: 'Tags'},
        {
            key: 'tweetnumber',
            label: 'Tweets',
            filter: false,
            _style: 'width:1%'
        },
        {
            key: 'followers',
            filter: false,
            _style: 'width:1%'
        },
        {
            key: 'followings',
            filter: false,
            _style: 'width:1%'
        },
        // {
        //     key: 'show_details',
        //     label: 'Details',
        //     _style: 'width:1%',
        //     sorter: false,
        //     filter: false
        // }
    ];

    export default {
        name: 'UserList',
        data() {
            return {
                fields,
                details: [],
                collapseDuration: 0,
                itemToggler: [],
            }
        },
        components: {
            TagSelect
        },
        methods: {
            ...mapActions(['requestCurrentTwitterUsers']),
            getDateInFormat(date, formatstr) {
                if (typeof date === "string") {
                    return format(new Date(date), formatstr, {locale: de})
                }
                return "-"
            },
            getFullProfilePicUrl(url) {
                return url.replace('_normal', '');
            },
            toggleDetails(index) {
                this.itemToggler[index] = !this.itemToggler[index];
                this.collapseDuration = 300;
                this.$nextTick(() => {
                    this.collapseDuration = 0
                })
            },
            rowClicked(item, index, column, e) {
                // this.toggleDetails(index)
                // if (column === 'name') {
                this.$router.push("/twitter/accounts/" + item.id)
                // }
            },
        },
        computed: {
            ...mapGetters([
                'getCurrentTwitterUsers',
                'getCurrentUsersLoading',
                'getCurrentUsersNeedReloading',
            ]),
            users() {
                return this.getCurrentTwitterUsers
            },
            usersLoading() {
                return this.getCurrentUsersLoading
            },
        },
        mounted() {
            //this.requestCurrentTwitterUsers()
        },
        destroyed() {
            console.log("Users destroyed");
        },
        activated() {
            console.log("UserList activated");
            if(this.getCurrentUsersNeedReloading){
                console.log("UserList needs reloading");
                this.requestCurrentTwitterUsers()
            }
        },
        deactivated() {
            console.log("UserList deactivated");
        }
    }
</script>
